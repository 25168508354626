import axios from "axios";
export interface IForgotPassword {
  email: string;
}

export interface IResetPassword {
  code: string;
  password: string;
  id: string;
  email: string;
}

export interface ILogin {
  email: string;
  password: string;
}

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

export const forgotPassword = (data: IForgotPassword) =>
  axios.post(`${baseUrl}/account/forgot-password`, data);

export const resetPassword = (data: IResetPassword) =>
  axios.post(`${baseUrl}/account/reset-credentials`, data);

export const login = (data: ILogin) =>
  axios.post(`${baseUrl}/account/login`, data);
